



























import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'EmptyLayout',
    components: {}
})
export default class EmptyLayout extends Vue {
    @Prop({ type: Object, default: {} })
    private error: any;

    get message() {
        return this.statusCode === 404
            ? '404 找不到页面'
            : '出错了，请稍后重试';
    }

    get statusCode() {
        return (this.error && this.error.statusCode) || 500;
    }
}
